import React, { useEffect, useState } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import supabase from '../supabase';
import { ResponsiveBar } from '@nivo/bar';
import './Statistics.css'; // Create and import the CSS file for transitions
import { useSubscription } from '../contexts/DashboardContext';
import { useTheme } from '../contexts/ThemeContext';

const countries = {
    us: 'USA',
    uk: 'UK',
    ca: 'Canada',
    de: 'Germany',
    es: 'Spain',
    fr: 'France',
    it: 'Italy',
    jp: 'Japan',
    in: 'India',
    cn: 'China',
    sg: 'Singapore',
    mx: 'Mexico',
    ae: 'UAE',
    br: 'Brazil',
    nl: 'Netherlands',
    au: 'Australia',
    tr: 'Turkey',
    sa: 'Saudi Arabia',
    se: 'Sweden',
    pl: 'Poland',
    etc: 'Other'
};

const Statistics = () => {
    const [tableData, setTableData] = useState([]);
    const [selectedLinks, setSelectedLinks] = useState([]);
    const [allLinks, setAllLinks] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [openDropdowns, setOpenDropdowns] = useState({});
    const [checkedLinks, setCheckedLinks] = useState({});
    const subscription = useSubscription();
    const { theme: siteTheme, toggleTheme } = useTheme();

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            window.scrollTo(0, 0);
            try {
                const user = await supabase.auth.getUser();
                const { data, error } = await supabase
                    .from('links')
                    .select('*')
                    .eq('user_id', user.data.user.id);

                if (error) {
                    throw error;
                }

                // Process data for table
                const processedData = data.map((item) => {
                    let name = item.name.length > 18 ? `${item.name.substring(0, 15)}...` : item.name;

                    const newData = { country: name, totalClicks: 0, clicks: {} };
                    Object.keys(countries).forEach((code) => {
                        if (item[code] > 0) {
                            newData.clicks[countries[code]] = item[code];
                            newData.totalClicks += item[code];
                        }
                    });

                    return newData;
                });

                setTableData(processedData);
                const linkNames = data.map(link => link.name);
                setAllLinks(linkNames);
                // Select all links by default
                setSelectedLinks(linkNames);
                // Open all dropdowns by default
                const initialOpenState = {};
                const initialCheckedState = {};
                linkNames.forEach((linkName, index) => {
                    initialOpenState[linkName] = true;
                    initialCheckedState[linkName] = index < 2; // Check first 3 checkboxes
                });
                setOpenDropdowns(initialOpenState);
                setCheckedLinks(initialCheckedState);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);


    const toggleDropdown = (linkName) => {
        setOpenDropdowns((prevOpenDropdowns) => ({
            ...prevOpenDropdowns,
            [linkName]: !prevOpenDropdowns[linkName],
        }));
    };
    const toggleCheck = (linkName) => {
        // Count the number of currently checked links
        const numChecked = Object.values(checkedLinks).filter((checked) => checked).length;
        // If the link is currently checked, uncheck it; otherwise, check it
        setCheckedLinks((prevCheckedLinks) => ({
            ...prevCheckedLinks,
            [linkName]: !prevCheckedLinks[linkName],
        }));
        // If the number of checked links exceeds 3, disable all unchecked checkboxes
        if (numChecked >= 2) {
            setCheckedLinks((prevCheckedLinks) => {
                const updatedCheckedLinks = { ...prevCheckedLinks };
                for (const [key, value] of Object.entries(updatedCheckedLinks)) {
                    if (!value && !selectedLinks.includes(key)) {
                        updatedCheckedLinks[key] = true; // Disable unchecked checkboxes
                    }
                }
                return updatedCheckedLinks;
            });
        }
    };

    const theme = {
        axis: {
            ticks: {
                text: {
                    fill: (siteTheme === 'dracula' ? 'white' : 'black'), // Customized text color
                    fontSize: 10
                }
            },
            legend: {
                text: {
                    fill: (siteTheme === 'dracula' ? 'white' : 'black'), // Customized legend text color
                    fontSize: 14
                }
            }
        },
        legends: {
            text: {
                fill: (siteTheme === 'dracula' ? 'white' : 'black'), // Customized legend text color
                fontSize: 10
            }
        }
    };

    // Define a custom color palette
    const colors = [
        '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
        '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
        '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
        '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
        '#35FFFA'
    ];


    const filteredData = tableData.filter(
        (link) =>
            selectedLinks.includes(link.country) &&
            (checkedLinks[link.country] || openDropdowns[link.country] || Object.keys(checkedLinks).some((key) => checkedLinks[key]))
    );

    const chartData = tableData
        .filter((link) => selectedLinks.includes(link.country) && checkedLinks[link.country])
        .map((link) => ({
            country: link.country,
            ...link.clicks
        }));


    const keys = [...new Set(chartData.flatMap((d) => Object.keys(d).filter((key) => key !== 'country')))];


    return (
        <>
            <div className="pb-10 flex flex-col items-center w-full">
                <p className="mt-28 mb-5 text-4xl md:text-4xl font-bold flex justify-center">View Statistics</p>
                {subscription.subscription === process.env.REACT_APP_GETBOOK_AUTHOR_PRO ? (
                    <label htmlFor="showChartModal" className="btn btn-primary mb-4">Show Chart</label>
                ) : (
                    <div className='items-center flex flex-col'>
                        <p className='text-error text-xs mb-4'>Upgrade to Getbook Author Pro to use the Show Chart feature!</p>
                        <label className="btn btn-primary btn-disabled mb-4">Show Chart</label>
                    </div>
                )}
                <div className="w-4/5 mx-auto">
                    <TransitionGroup component={null}>
                        {filteredData.map((link, index) => (
                            <CSSTransition
                                key={index}
                                timeout={500} // Adjusted the timeout to match the CSS
                                classNames="fade"
                            >
                                <div className="overflow-x-auto mb-8 w-full">
                                    <div className="flex items-center mb-2">
                                        <input
                                            type="checkbox"
                                            checked={checkedLinks[link.country]}
                                            onChange={() => toggleCheck(link.country)}
                                            disabled={(!checkedLinks[link.country] && Object.values(checkedLinks).filter((checked) => checked).length >= 2) || subscription.subscription !== process.env.REACT_APP_GETBOOK_AUTHOR_PRO}
                                            tooltip-data="Charts are a Getbook Author Pro feature. Upgrade in the Subscription tab."
                                            className={`checkbox ${subscription.subscription !== process.env.REACT_APP_GETBOOK_AUTHOR_PRO ? 'hidden' : ''}`}
                                        />
                                        <button
                                            onClick={() => toggleDropdown(link.country)}
                                            className={`btn btn-ghost flex items-center ${subscription.subscription !== process.env.REACT_APP_GETBOOK_AUTHOR_PRO ? '' : 'ml-2'}`}
                                        >
                                            {openDropdowns[link.country] ? (
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                                </svg>
                                            ) : (
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                                                </svg>
                                            )}
                                        </button>
                                        <a className='text-2xl font-bold ml-2'>{link.country}</a>
                                    </div>
                                    <div className="text-sm mb-4">Total Clicks: {link.totalClicks.toLocaleString()}</div>
                                    <CSSTransition
                                        in={openDropdowns[link.country]}
                                        timeout={500}
                                        classNames="slide-up"
                                        unmountOnExit
                                    >
                                        <table className="table w-full">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Country</th>
                                                    <th>Clicks</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Object.entries(link.clicks).length > 0 ? (
                                                    Object.entries(link.clicks).map(([country, clicks], idx) => (
                                                        <tr key={idx} className="hover">
                                                            <th>{idx + 1}</th>
                                                            <td>{country}</td>
                                                            <td>{clicks.toLocaleString()}</td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="3" className="text-center">No clicks yet!</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </CSSTransition>
                                </div>
                            </CSSTransition>
                        ))}
                    </TransitionGroup>
                </div>
                {/* Put this part before </body> tag */}
                <input type="checkbox" id="showChartModal" className="modal-toggle" />
                <div className="modal" role="dialog">
                    <div className="modal-box w-11/12 max-w-5xl">
                        <h3 className="ml-4 font-bold text-lg">Link Statistics</h3>
                        <div style={{ height: 400 }}>
                            <ResponsiveBar
                                data={chartData}
                                keys={keys}
                                indexBy="country"
                                margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                                padding={0.3}
                                valueScale={{ type: 'linear' }}
                                indexScale={{ type: 'band', round: true }}
                                defs={[
                                    {
                                        id: 'dots',
                                        type: 'patternDots',
                                        background: 'inherit',
                                        color: '#38bcb2',
                                        size: 4,
                                        padding: 1,
                                        stagger: true
                                    },
                                    {
                                        id: 'lines',
                                        type: 'patternLines',
                                        background: 'inherit',
                                        color: '#eed312',
                                        rotation: -45,
                                        lineWidth: 6,
                                        spacing: 10
                                    }
                                ]}
                                fill={[
                                    {
                                        match: {
                                            id: 'fries'
                                        },
                                        id: 'dots'
                                    },
                                    {
                                        match: {
                                            id: 'sandwich'
                                        },
                                        id: 'lines'
                                    }
                                ]}
                                borderWidth={2}
                                tooltip={({ id, value, indexValue }) => (
                                    <div
                                        style={{
                                            padding: '12px',
                                            background: 'rgba(0, 0, 0, 0.6)',
                                            color: 'white',
                                            borderRadius: '2px',
                                        }}
                                    >
                                        <strong>
                                            {id}: {value} click{value > 1 ? 's' : ''}
                                        </strong>
                                    </div>
                                )}
                                borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                axisTop={null}
                                axisRight={null}
                                axisBottom={{
                                    tickSize: 5,
                                    tickPadding: 2,
                                    tickRotation: 0,
                                    legend: 'Links',
                                    legendPosition: 'middle',
                                    legendOffset: 40
                                }}
                                axisLeft={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: 'Clicks',
                                    legendPosition: 'middle',
                                    legendOffset: -40
                                }}
                                labelSkipWidth={12}
                                labelSkipHeight={12}
                                labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                legends={[
                                    {
                                        dataFrom: 'keys',
                                        anchor: 'bottom-right',
                                        direction: 'column',
                                        justify: false,
                                        translateX: 120,
                                        translateY: 0,
                                        itemsSpacing: 2,
                                        itemWidth: 100,
                                        itemHeight: 20,
                                        itemDirection: 'left-to-right',
                                        itemOpacity: 0.85,
                                        symbolSize: 20,
                                        effects: [
                                            {
                                                on: 'hover',
                                                style: {
                                                    itemOpacity: 1
                                                }
                                            }
                                        ]
                                    }
                                ]}
                                theme={theme}
                                colors={colors}
                                role="application"
                                ariaLabel="Nivo bar chart demo"
                                barAriaLabel={(e) => `${e.id}: ${e.formattedValue} in country: ${e.indexValue}`}
                            />
                        </div>
                        <div className="modal-action">
                            <label htmlFor="showChartModal" className="btn">Close</label>
                        </div>
                    </div>
                </div>
            </div>
            {isLoading && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
                </div>
            )}
        </>
    );
};

export default Statistics;
